<template>
  <div class="my-container">
    <div class="top">
      <div class="first">
        <div class="item column-center" @click="$router.push('/home/notice')">
          <img src="@/assets/images/my/客服.png" />
          <span>Suporte</span>
        </div>
        <van-badge :content="shownumber?shownumber:''">
          <div class="item column-center" @click="$router.push('/home/notice?active=2')">
            <img src="@/assets/images/my/信息.png" />
            <span class="van-ellipsis">Mensagens</span>
          </div>
        </van-badge>
        <div class="item column-center" @click="$router.push('/home/setting')">
          <img src="@/assets/images/my/个人.png" />
          <span>Dados</span>
        </div>
      </div>
      <div class="second">
        <img @click="$router.push('/home/setting')" class="avatar" src="@/assets/images/my/avatar.png" />
        <div class="detail">
          <div class="one">
            <span>Conta: {{ userInfo.username }}</span>
            <span style="color: #fff;"></span>
          </div>
          <div class="two">
            <span style="border-right: 1px solid; padding-right: 5px">
              <span>ID：</span>
              <span style="
                  display: inline-block;
                  width: 85px;
                  color: #fff;
                  font-size: x-small;
                  word-wrap: break-word;
                ">{{ userInfo.id }}</span>
              <img class="small-icon" src="@/assets/images/my/复制.png" @click="copyToClipboard"/>
            </span>
            <span>
              <img class="small-icon" src="@/assets/images/my/blance.png" />
              <span style="padding-left: 0.1rem;">{{ balance | formatNumber}}</span>
              <!-- <img @click="rotateImage(this)" class="small-icon" src="@/assets/images/my/刷新.png" /> -->
              <img
                style="width:25px"
                :src="imageUrl"
                alt="旋转图片"
                @click="rotateImage"
                :style="{ transform: `rotate(${rotation}turn)` }"
              >
            </span>
          </div>
        </div>
      </div>
      <div class="items">
<!--        <div class="item column-center">-->
<!--          <img src="@/assets/images/my/盒子.png" />-->
<!--          <span>Recuperar o saldo</span>-->
<!--        </div>-->
<!--        <div class="item column-center">-->
<!--          <router-link to="/home/withdraw" class="column-center">-->
<!--            <img src="@/assets/images/my/优惠卷.png" />-->
<!--            <span>Saque</span>-->
<!--          </router-link>-->
<!--        </div>-->
        <div class="item column-center" @click="isSet('/home/withdraw')">
          <img src="@/assets/images/my/优惠卷.png" />
          <span>Saque</span>
        </div>
        <div class="item column-center" @click="$router.push('/home/deposit')">
          <img src="@/assets/images/my/理财.png" />
          <span>Depósito</span>
        </div>
        <div class="item column-center" @click="$router.push('/home/event')">
          <img src="@/assets/images/my/存钱罐.png" />
          <span>Juros</span>
        </div>
      </div>
      <div class="bottom">
        <div class="one">
          <div class="vip center">
            <img src="@/assets/images/my/vip.png" />
            <span style="color: #feb705;">{{userInfo.lvId}}</span>
          </div>
            <div>
                <span style="color: grey;">Restantes</span>
                <span style="color: black;"> {{userInfo.nextVipName}} </span>
            </div>
            <div style="display: flex;align-items: center">
                <div style="display: flex;flex-direction: column;align-items: flex-end;">
                    <div>
                        <span style="color: grey;"> Codificação </span>
                        <span style="color: black;"> {{userInfo.nextExpLimit | formatNumber}}</span>
                    </div>
                    <div>
                        <span style="color: grey;"> Acumulado de recarga </span>
                        <span style="color: black;"> {{userInfo.nextRechargeLimit | formatNumber}}</span>
                    </div>
                </div>
            </div>
<!--          <van-icon name="arrow" />-->
        </div>
        <div class="two">
            <img :src="userInfo.vipIcon" />
            <div class="two-1">
                <div class="two-1-1">
                    <div style="color:gray">Depósito para promoção</div>
                    <div class="process">
                        <van-progress :percentage="50" :pivot-text="levelPro" color="#32CD32" />
                    </div>
                </div>
                <div class="two-1-1">
                    <div style="color:gray">Aposta para promoção</div>
                    <div class="process">
                        <van-progress :percentage="50" :pivot-text="recharge" color="#32CD32" />
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="nav-one">
<!--      <van-cell :icon="require('@/assets/images/my/01.png')" @click="$router.push('/home/record?num=1')" title="Detalhes da Conta" is-link center :border="false">-->
<!--      </van-cell>-->
<!--      <van-cell :icon="require('@/assets/images/my/02.png')" @click="$router.push('/home/record?num=2')" title="Recordes de Apostas" is-link center :border="false">-->
<!--      </van-cell>-->
      <van-cell :icon="require('@/assets/images/my/03.png')" @click="$router.push('/home/record?num=3')" title="Relatório" is-link center :border="false">
      </van-cell>
        <van-cell :icon="require('@/assets/images/my/04.png')" title="Gerenciamento de saque" is-link center
                  to="/home/withdraw" :border="false">
        </van-cell>
        <van-cell :icon="require('@/assets/images/my/hongbao.png')" title="Resgatar código" @click="showDHM=true"  is-link center   :border="false">
        </van-cell>
    </div>
    <div class="nav-two">
      <van-cell :icon="require('@/assets/images/my/05.png')" title="Convidar" value="milhões por mês" is-link center
        @click="$router.push('/home/promote')" :border="false">
      </van-cell>
      <van-cell :icon="require('@/assets/images/my/06.png')" title="Dados" is-link center
                @click="$router.push('/home/setting')" :border="false">
      </van-cell>
      <van-cell v-if="isSetPayPassword !=1" :icon="require('@/assets/images/my/13.png')" title="Configurar senha de pagamento" is-link center
                @click="$router.push('/home/payPassword')" :border="false">
      </van-cell>
      <van-cell v-else :icon="require('@/assets/images/my/13.png')" title="Configurar senha de pagamento" value="Foi definido" is-link center
                 :border="false">
      </van-cell>
      <van-cell :icon="require('@/assets/images/my/07.png')" title="Centro de Segurança" is-link center :border="false">
      </van-cell>
<!--      <van-cell :icon="require('@/assets/images/my/08.png')" title="Música" is-link center :border="false">-->
<!--      </van-cell>-->
      <van-cell :icon="require('@/assets/images/my/09.png')" title="Idioma" value="Português" is-link center
        :border="false">
      </van-cell>
      <van-cell :icon="require('@/assets/images/my/10.png')" title="FAQ" is-link center @click="$router.push('/home/notice')" :border="false">
      </van-cell>
      <van-cell :icon="require('@/assets/images/my/11.png')" title="Bônus de Sugestão" is-link center  @click="$router.push('/home/notice?active=4')" :border="false">
      </van-cell>
      <van-cell :icon="require('@/assets/images/my/12.png')" title="Sair" is-link center :border="false"
        @click="$store.dispatch('logout')">
      </van-cell>
    </div>
      <van-popup v-model="showDHM" class="DHM_css">
          <div class="bg">
              <h2>Resgatar código</h2>
              <input type="text" v-model="inputCode" placeholder="Por favor insira o código de resgate">
              <div class="btn_css">
                  <div @click="showDHM=false" class="left_css">Cancelar</div>
                  <div @click="exchangeCode" class="right_css">Enviar</div>
              </div>
          </div>
      </van-popup>
  </div>
</template>

<script>
import {exchangeCode, getUserInfoApi} from "@/api";

export default {
  data() {
    return {
      shownumber: 0,
      userInfo: this.$store.state.userInfo,
      imageUrl: require('@/assets/images/my/刷新.png'),
      rotation: 0,
      isRotating: false,
      levelPro: parseInt(this.$store.state.userInfo.bonusProcceed) + "/" + this.$store.state.userInfo.nextExpLimit,
      recharge: parseInt(this.$store.state.userInfo.rechargeSum) + "/" + this.$store.state.userInfo.nextRechargeLimit,
      balance:'',
      isSetPayPassword:'',
      showDHM:false,
      timerId:null,
      inputCode:'',
    }
  },
  computed: {
    getBackgroundStyle() {
      const percentage = this.userInfo.bonusProcceed / this.userInfo.nextExpLimit;
      const greenPercentage = Math.min(percentage * 100 * (2 / 5), 100); // 计算绿色部分的百分比，最大为100%
      return `linear-gradient(to right, #04be02 ${greenPercentage}%, transparent ${greenPercentage}%)`;
    },
  },
  mounted() {
    // console.log(this.$store.state.userInfo)
      if(this.$route.query.show!=null){
          this.showDHM = this.$route.query.show
      }else {
          this.showDHM = false;
      }
    this.getUserInfoApi();
  },
  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.userInfo.id)
        this.$toast.success('A cópia foi bem sucedida')
      } catch (err) {
        console.error('Não é possível copiar o texto: ', err)
      }
    },
    rotateImage() {
      if (!this.isRotating) {
        this.isRotating = true;
        this.rotation += 2; // 旋转两圈
        this.timerId = setTimeout(() => {
          this.isRotating = false;
        }, 2000); // 旋转动画持续时间 2 秒
      }
      getUserInfoApi().then(res=>{
          if (res.code==200){
              this.balance = res.data.balance
              console.log(this.balance)
          }
      })
    },
    getUserInfoApi(){
      getUserInfoApi().then(res=>{
          if (res.code ==200){
              this.balance = res.data.balance
              this.isSetPayPassword = res.data.isSetPayPassword
              this.userInfo = res.data;
              this.levelPro = parseInt(res.data.bonusProcceed) + "/" + res.data.nextExpLimit;
              this.recharge = parseInt(res.data.rechargeSum) + "/" + res.data.nextRechargeLimit;
              console.log("this.isSetPayPassword:"+this.isSetPayPassword)
          }
      })
    },
    isSet(url){
      if (this.isSetPayPassword ==1){
        this.$router.push(url)
      }else{
        // setTimeout(()=>{
          this.$toast.fail("Por favor, defina sua senha de pagamento aqui primeiro")
        // },100)
        this.$router.push('/home/payPassword')
      }
    },
      exchangeCode(){
        console.log(this.inputCode)
          exchangeCode(this.inputCode).then(res=>{
              if(res.code==200){
                  this.$toast.success(res.msg)
                  this.showDHM = false
              }else{
                  this.$toast.fail(res.msg)
              }
          })
      },
  },
    beforeDestroy() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
        if (this.timerId2) {
            clearTimeout(this.timerId2);
            this.timerId2 = null;
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep(.van-progress__portion){
  width: 0 !important;
}
::v-deep(.van-progress__pivot){
  left: 0 !important;
  padding: 0 15px;
}
.van-cell {
  background: black;
  height: 0.6667rem;
  .van-cell__left-icon {
    margin-right: 0.2rem;
  }

  .van-cell__left-icon:first-child {
    color: #fed005e7;
  }
}

.my-container {
  background-color: rgb(48, 48, 48);
  padding-bottom: 0.7rem;
  //   height: 10.0242rem;
  .top {
    background-color: black;
    background-image: url('~@/assets/images/my/bg.png');
    background-size: 100%;
    padding: 0 0.1rem;
    padding-bottom: 0.1rem;

    .first {
      display: flex;
      justify-content: end;
      gap: 0.15rem;
      padding: 0.1rem;
      .item {
        width: 0.5rem;
        span {
          display: inline-block;
          width: 100%;
        }
        img {
          width: 0.2667rem;
          height: 0.2667rem;
        }
      }
    }
    .second {
      display: flex;
      .avatar {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .detail {
        flex: 1;
        .two {
          font-size: 0.23rem;
          .small-icon {
            width: 0.2rem;
            margin-left: 0.1rem;
          }
        }
      }
    }
    .items {
      display: flex;
      .item {
        flex: 1;
        img {
          width: 0.3636rem;
          height: 0.3152rem;
        }
        span {
          margin-top: 0.1rem;
          text-align: center;
          line-height: normal;
        }
      }
    }
    .bottom {
      background-color: #feb705;
      border-radius: 0.1rem;
      padding: 0.1rem;
      margin-top: 0.1rem;
      .one {
        display: flex;
        align-items: center;
          justify-content: space-evenly;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid rgba(0, 0, 0, 0.1);

        .vip {
          width: 0.5152rem;
          height: 0.2rem;
          background: rgb(36, 178, 153);
          border-radius: 0.1rem 0 0.1rem 0;
          margin-right: 0.1rem;

          img {
            width: 0.3rem;
            height: 0.12rem;
          }
        }
      }
      .two {
        padding: 0.1rem;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        img {
          width: 0.7rem;
          height: 0.7rem;
        }
          .two-1{
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
              .two-1-1{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size:12px;
                  margin:5px 0;
                  .process {
                      flex: 1;
                      min-width: 100px;
                      margin-left: 10px;
                      div {
                          text-align: center;
                          height: 0.2rem;
                          line-height: 0.2rem;
                          border-radius: 0.1rem;
                          color: #fff;
                      }
                  }
              }
          }
      }
    }
  }
  .nav-one {
    padding-bottom: 0.15rem;
  }
}

img {
  transition: transform 2s ease; /* 旋转动画持续时间和缓动函数 */
}

.DHM_css{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 330px;
    background: #30303000;
    .bg{
        background: url("../../assets/ditu.png");
        background-size: 100% 100%;
        width: 100%;
        padding: 130px 30px 30px 30px;
        h2{
            color:#fff;
            display: none;
        }
        input{
            width: 100%;
            height: 30px;
            color: #fff;
            background: #00000000;
            border: 1px solid #817e7e;
            border-radius: 5px;
        }
        .btn_css{
            display: flex;
            width: 100%;
            cursor: pointer;
            margin: 10px 0;
            .left_css{
                color: #fff;
                width: 50%;
                text-align: center;
                background: #545454;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 15px;
                border-radius: 5px;
            }
            .right_css{
                color: #fff;
                width: 50%;
                text-align: center;
                background: #f8ce01;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
            }
        }
    }
}
</style>
